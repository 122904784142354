/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from "react";
import {
  MetaFunction,
  LoaderFunctionArgs,
  ActionFunctionArgs,
} from "@remix-run/node";
import { Form, useLoaderData, useNavigation, Link, redirect } from "@remix-run/react";
import { PageShell } from "~/components/layouts/page-shell";
import { Button } from "~/components/ui/button";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { EnvelopeClosedIcon, LockClosedIcon } from "@radix-ui/react-icons";
import { authenticator, EMAIL_PASSWORD_STRATEGY } from "~/server/auth.server";
import { getSession, commitSession } from "~/server/session.server";
import { useToast } from "~/hooks/use-toast";
import { Alert, AlertDescription, AlertTitle } from "~/components/ui/alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Checkbox } from "~/components/ui/checkbox";
import { AppRoutes } from "~/lib/routes";

export const meta: MetaFunction = () => {
  return [{ title: "Login | QuickBridge" }];
};

export default function Login() {
  const loaderData = useLoaderData<any>();
  const navigation = useNavigation();
  const { toast } = useToast();
  const error = loaderData?.error;

  useEffect(() => {
    if (error?.message) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
      });
    }
  }, [error?.message, toast]);

  return (
    <PageShell>
      <div className="flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8 bg-zinc-100 dark:bg-zinc-800">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">
              Welcome Back
            </CardTitle>
            <CardDescription className="text-center">
              Sign in to your account
            </CardDescription>
          </CardHeader>
          <CardContent>
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertTitle>Error</AlertTitle>
                <AlertDescription>{error.message}</AlertDescription>
              </Alert>
            )}
            <Form method="post" className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="username">Email address</Label>
                <div className="relative">
                  <EnvelopeClosedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-400" />
                  <Input
                    id="username"
                    name="username"
                    type="email"
                    autoComplete="username"
                    required
                    className="pl-10"
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div className="space-y-2">
                <Label htmlFor="password">Password</Label>
                <div className="relative">
                  <LockClosedIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 text-zinc-400" />
                  <Input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="pl-10"
                    placeholder="Enter your password"
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                  <Checkbox id="remember-me" />
                  <Label htmlFor="remember-me" className="text-sm">
                    Remember me
                  </Label>
                </div>
                <Button variant="link" className="text-sm">
                  Forgot password?
                </Button>
              </div>
              <Button
                type="submit"
                className="w-full"
                disabled={navigation.state === "submitting"}
              >
                {navigation.state === "submitting"
                  ? "Signing in..."
                  : "Sign in"}
              </Button>
            </Form>
          </CardContent>
          <CardFooter className="justify-center">
            <p className="text-sm text-zinc-600 dark:text-zinc-400">
              Don&apos;t have an account?{" "}
              <Link to="/signup">
                <Button variant="link" className="p-0">
                  Sign up
                </Button>
              </Link>
            </p>
          </CardFooter>
        </Card>
      </div>
    </PageShell>
  );
}

export async function action({ request }: ActionFunctionArgs) {
  try {
    const user = await authenticator.authenticate(EMAIL_PASSWORD_STRATEGY, request);

    const session = await getSession(request.headers.get("cookie"));
    session.set("user", user);

    // commit the session
    const headers = new Headers({ "Set-Cookie": await commitSession(session) });

    return redirect(AppRoutes.Profile.Select, { headers });
  } catch (error) {
    console.error(error);
    return redirect(AppRoutes.LOGIN);
  }
}

export async function loader({ request }: LoaderFunctionArgs) {
  // Check if user is already authenticated
  const session = await getSession(request.headers.get("cookie"));
  const user = session.get("user");
  if (user) {
    throw redirect(AppRoutes.Dashboard.Index);
  }


  // Commit the session changes
  return null;
}
